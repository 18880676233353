<template>
    <div class="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-5xl w-full flex">
            <div class="w-1/2 mr-4">
                <h3 class="text-lg leading-6 font-medium text-gray-900">폴더 목록</h3>
                <input v-model="roomFilter" type="text" placeholder="Search..." class="mt-2 mb-2 w-full">
                <ul v-if="roomList.length > 0" class="border-t border-b border-gray-200 divide-y divide-gray-200">
                    <li v-for="(room, index) in filteredRoomList" :key="index" class="py-3 px-2 flex justify-between items-center" :class="{ 'bg-gray-200': room.room === curRoom }">
                        <div>
                            <p class="text-sm font-medium text-gray-900">
                                {{ room.room }}
                                <span class="text-sm text-gray-500">({{ room.fileCount }}개 파일, {{ formatSize(room.totalSize) }})</span>
                            </p>
                        </div>
                        <div>
                            <button @click.prevent="viewRoom(room.room)" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">보기</button>
                            <button @click.prevent="deleteRoom(room.room)" class="text-sm font-medium text-indigo-600 hover:text-indigo-500 ml-2">삭제</button>
                        </div>
                    </li>
                </ul>
                <p v-else class="mt-2 text-sm text-gray-500 text-center">폴더가 없습니다.</p>
            </div>
            <div class="w-1/2 ml-4">
                <h3 class="text-lg leading-6 font-medium text-gray-900">파일 목록</h3>
                <input v-model="fileFilter" type="text" placeholder="Search..." class="mt-2 mb-2 w-full">
                <button @click.prevent="selectAll" class="mt-2 text-sm font-medium text-indigo-600 hover:text-indigo-500">전체 선택</button>
<button @click.prevent="deleteFiles" class="mt-2 ml-4 text-sm font-medium text-indigo-600 hover:text-indigo-500">선택 삭제</button>
                <ul v-if="fileList.length > 0" class="border-t border-b border-gray-200 divide-y divide-gray-200">
                    <li v-for="(file, index) in filteredFileList" :key="index" class="py-3 px-2 flex justify-between items-center">
                        <div class="flex items-center">
                            <input type="checkbox" v-model="selectedFiles" :value="file.name">
                            <p class="text-sm font-medium text-gray-900 ml-2">{{ file.name }} ({{ formatSize(file.size) }})</p>
                        </div>
                    </li>
                </ul>
                <p v-else class="mt-2 text-sm text-gray-500 text-center">파일이 없습니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import router from '../router';

const http = axios.create({
    baseURL: (location.host !== "rtc.e-human.co.kr" && location.host !== "record.e-human.co.kr")
        ? 'http://localhost:8080' : '',
});

http.interceptors.response.use(null, function(error) {
    if (error.response.status === 401) {
        alert('로그인이 필요합니다');
        router.push('/adm');
    }
    return Promise.reject(error);
});

export default {
    name: 'Download',
    data: function () {
        return {
            roomName: '',
            userId: '',
            baseUrl: this.$debug ? 'http://localhost:8080' : '',
            roomList: [],
            fileList: [],
            roomFilter: '',
            fileFilter: '',
            curRoom: '',
            selectedFiles: [],
        }
    },
    mounted: function () {
        this.getRoomList();
    },
    computed: {
        filteredRoomList: function() {
            return this.roomList.filter(room => room.room.includes(this.roomFilter));
        },
        filteredFileList: function() {
            return this.fileList.filter(file => file.name.includes(this.fileFilter));
        },
    },
    watch: {
        fileFilter: function() {
            this.selectedFiles = [];
        },
        roomFilter: function() {
            this.selectedFiles = [];
        }
    },
    methods: {
        formatSize: function(size) {
            if (size >= 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
            } else if (size >= 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB';
            } else if (size >= 1024) {
                return (size / 1024).toFixed(2) + ' KB';
            }
            return size + ' bytes';
        },
        getRoomList: function() {
            http.get(this.baseUrl + '/api/admin/room-list')
                .then((res) => {
                    this.roomList = res.data;
                })
                .catch((error) => {
                    console.error(error);
                    alert('방 목록을 불러오는데 실패했습니다.');
                });
        },
        viewRoom: function(roomName) {
            http.get(this.baseUrl + '/api/admin/room-view', { params: { roomName: roomName } })
                .then((res) => {
                    // this.fileList = res.data.map(file => file.name);
                    this.fileList = res.data;
                    this.curRoom = roomName;
                })
                .catch((error) => {
                    console.error(error);
                    alert('파일 목록을 불러오는데 실패했습니다.');
                });
        },
        deleteRoom: function(roomName) {
            if (!confirm('정말로 삭제하시겠습니까?')) {
                return;
            }
            http.post(this.baseUrl + '/api/admin/delete-room', { roomName: roomName })
                .then(() => {
                    alert('방이 성공적으로 삭제되었습니다.');
                    // 방 목록을 다시 불러옵니다.
                    this.getRoomList();
                    // 파일 목록을 초기화합니다.
                    this.fileList = [];
                })
                .catch((error) => {
                    console.error(error);
                    alert('방을 삭제하는데 실패했습니다.');
                });
        },
        deleteFiles: function() {
            if (!confirm('정말로 선택한 파일들을 삭제하시겠습니까?')) {
                return;
            }
            http.post(this.baseUrl + '/api/admin/delete-files', { roomName: this.curRoom, files: this.selectedFiles })
                .then(() => {
                    alert('파일이 성공적으로 삭제되었습니다.');
                    // 파일 목록을 다시 불러옵니다.
                    this.viewRoom(this.curRoom);
                })
                .catch((error) => {
                    console.error(error);
                    alert('파일을 삭제하는데 실패했습니다.');
                });
        },
        selectAll: function() {
            if (this.selectedFiles.length < this.filteredFileList.length) {
                this.selectedFiles = this.filteredFileList.map(file => file.name);
            } else {
                this.selectedFiles = [];
            }
        },
    }
}
</script>